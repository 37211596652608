
import {
  Button,
  Modal
} from "react-bootstrap";
import { useEffect, useState } from "react";
import ModalComp from "./components/ModalComp";
import "./App.css"

const App = () => {

  const [show, setShow] = useState(false);

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [genero, setGenero] = useState("Irmãs");
  const [color,setColor] = useState('#f3d6f2');
  const [bgcolor,setBGColor] = useState('pink');
  const [db, setDB] = useState("ccb_staceia_irmas");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const staceia = localStorage.getItem(db)
      ? JSON.parse(localStorage.getItem(db))
      : [];

    setData(staceia);

  }, [setData, setGenero, db]);


  const total = data.map(d => d.qtde).reduce((a, b) => parseInt(a)+parseInt(b), 0)

  const calc_geral = () => {
    const d1 = localStorage.getItem(db) ? JSON.parse(localStorage.getItem( 'ccb_staceia_irmas')) : [];
    const d2 = localStorage.getItem(db) ? JSON.parse(localStorage.getItem( 'ccb_staceia_irmaos')) : [];

    let x1 = 0;
    let x2 = 0;
    if (d1) { 
      x1 = (d1.map(d => d.qtde).reduce((a, b) => parseInt(a)+parseInt(b), 0));
    }
    if (d2){
      x2 = (d2.map(d => d.qtde).reduce((a, b) => parseInt(a)+parseInt(b), 0));
    }
    return (x1+x2);
  
    
  };

  return (

    <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12"  align="center" >
      <div style={{backgroundColor: color, width: "100%", height:"100%",borderRadius: "20px"}}>
        <div> 
          <Button style={{fontSize: "28px" , color: "#1d1717", backgroundColor: "#f5a1d9", marginTop: "20px", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}} 
            onClick={() => [setGenero("Irmãs"), setColor('#f3d6f2'), setDB('ccb_staceia_irmas'), setBGColor('#D4A8D6')]}>
            Irmãs
          </Button>
          <Button style={{fontSize: "28px" , color: "#1d1717", backgroundColor:  "#aed4f3", marginTop: "20px", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)"}}
            onClick={() => [setGenero("Irmãos"), setColor('#8ac2f0'), setDB('ccb_staceia_irmaos'), setBGColor('#A8C7D6') ]}>
            Irmãos
          </Button>  
        </div>
        <br/>
       
        <div>
          <span style={{fontSize: "26px"}}>Rodadas de {genero}</span>     
        </div>
        
        <div style={{alignItems: "auto", justifyItems: "center", alignContent: "center", borderRadius: "20px"}}>
          { data.map(({ qtde, genero}, index) => (
            <Button 
              className="btn-rodada"
              style={{backgroundColor:  color }}
              onClick={() => [setDataEdit({ qtde, genero, index}), handleShow()]}
            >
              {qtde}
            </Button>
              
          ))}
          {/* <br/> */}
          <hr/>
          <div>
            
            <span style={{fontSize: "18px"}}>Total:</span>
            <Button style={{fontSize: "22px" , 
              color: "#f8f5f5", 
              backgroundColor: "#737762", 
              margin: "1%", 
              padding: ".8rem 0",
              boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
              width: "55px"}}>
                {calc_geral()} 
            </Button>

            <span style={{fontSize: "18px"}}>  {genero}:</span>
            <Button style={{fontSize: "22px" , 
              color: "#1d1717", 
              backgroundColor: color, 
              margin: "1%", 
              padding: ".8rem 0",
              boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
              width: "55px"}}>
              {total} 
            </Button>

           
          </div>
          <hr/> 
          <Button className="btn-registrar" 
            onClick={() => [setDataEdit({}), handleShow()]}>
            Registrar
          </Button>
        </div>
       
         
      </div>
      <Modal show={show} onHide={handleClose} style={{backgroundColor: color, width: "100%", height:"100%"}}>
        <ModalComp
            data={data}
            setData={setData}
            dataEdit={dataEdit}
            handleClose={handleClose}      
            setDataEdit={setDataEdit}
            genero={genero}
            color={color} 
            db={db}         
          />
      </Modal>     
     

    </div>
  );
};

export default App;
