import { Form, Button } from "react-bootstrap";

import { useState } from "react";

const ModalComp = ({
  data,
  setData,
  handleClose,
  dataEdit,
  genero,
  color,
  db,
}) => {
  const [show, setShow] = useState(false);

  const [qtde, setQtde] = useState(dataEdit.qtde || "");

  const handleRemove = (idx) => {

    const newArray = data.filter((item, index) => index !== idx);

    setData(newArray);

    localStorage.setItem(db, JSON.stringify(newArray));
  };

  const handleSave = (qtde) => {

    if (qtde === 0) {
      handleRemove(dataEdit.index);
      dataEdit = [];
    } else {
      if (dataEdit.index >= 0) {
        data[dataEdit.index] = { qtde, genero };
      }

      const newDataArray = !(dataEdit.index >= 0)
        ? [...(data ? data : []), { qtde, genero }]
        : [...(data ? data : [])];

      localStorage.setItem(db, JSON.stringify(newDataArray));

      setData(newDataArray);
    }
    handleClose();
  };

  const range = (n) =>
    Array(n + 1)
      .join(1)
      .split("")
      .map((x, i) => i);

  return (
    <>
      <div className="modal show responsive" >
          <div
            class="row col-xs-12 col-sm-12 col-md-12 col-lg-12"
            align="center"
            style={{ display: "block", position: "initial", borderRadius: "20px", backgroundColor: color}}
          >
            <Form className="responsive">
                {range(35).map((i) => (
                  <Button
                    style={{
                      fontSize: "22px",
                      float: "left",
                      position: "relative",
                      padding: "0 0 0 0",
                      color: "#1d1717",
                      backgroundColor: color,
                      borderRadius: "20px",
                      margin: "1%",
                      height: "35px",
                      width: "45px",
                      textAlign: "center",
                      
                      boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)",
                    }}
                    onClick={() => [handleSave(i)]}
                  >
                    {i}
                  </Button>
                ))}
            </Form>
          </div>
        </div>
    </>
  );
};
export default ModalComp;
